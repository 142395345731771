// Core modules
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppFeatures, ConfigService } from '../../../services/config.service';
import { RolesService } from '../../../services/roles.service';
import { ViewService } from '../../../services/view.service';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';
import { AuthenticationService } from '../../../services/authentication.service';

// import humanizeString = require('humanize-string');

/**
 * Client settings component
 */
@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss']
})
export class ClientSettingsComponent extends SurvaleCommonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() settings: any;

  public clientSettingsForm: FormGroup;
  public slackBotForm: FormGroup;
  public defaultEmailFooterText = 'This email is being sent from an unmonitored email address.' +
    'We can be reached at contactus@survale.com.<br/>\n' +
    'Survale offices are located at <br/>\n' +
    '2021 Fillmore St. #9035, San Francisco, CA, 94115<br/>\n' +
    '(888) 20-SURVALE<br/>\n' +
    '<a href="https://survale.com">https://survale.com</a>';
  public filteredTimeZones = [];
  public tzFilterCtrl: FormControl = new FormControl();
  public roles;
  public views;
  public features = _.values(AppFeatures);
  public tokenExpiryOptions = [
    { k: 30, v: '30 minutes' },
    { k: 60, v: '1 Hour' },
    { k: 3 * 60, v: '3 Hours' },
    { k: 6 * 60, v: '6 Hours' },
    { k: 8 * 60, v: '8 Hours' },
    { k: 24 * 60, v: '1 Day' },
    { k: 3 * 24 * 60, v: '3 Days' },
    { k: 5 * 24 * 60, v: '5 Days' },
    { k: 7 * 24 * 60, v: '1 Week' },
  ];
  protected _onDestroy = new Subject<void>();

  /**
   * Life cycle method
   */
  constructor(
    private fb: FormBuilder,
    private rolesService: RolesService,
    private viewService: ViewService,
    public authenticationService: AuthenticationService
  ) {
    super(authenticationService);
    this.filteredTimeZones = ConfigService.getTimeZones();
    // console.log('this.filteredTimeZones', this.filteredTimeZones);
  }

  get apiHost(): string {
    return ConfigService.config.apiHost;
  }
  /**
   * Life cycle init method
   */
  ngOnInit(): void {

    this.clientSettingsForm = this.fb.group({
      atsAuthProvider: new FormControl(),
      indexCalculations: new FormControl(),
      compareWidgetsEnabled: new FormControl(),
      excludeUnsubscribeLinkInEmail: new FormControl(),
      emailFooterText: new FormControl(),
      identifyClientsInUnsubscribeLink: new FormControl(),
      hideEmailTrackingCode: new FormControl(),
      insightsEnabled: new FormControl(),
      isMailBoxMonitored: new FormControl(),
      operatingCountry: new FormControl(),
      emailProvider: new FormControl(),
      replyToEmail: new FormControl(),
      replyToName: new FormControl(),
      systemReplyToEmail: new FormControl(),
      systemReplyToName: new FormControl(),
      smsEnabled: new FormControl(),
      forcePwdChange: new FormControl(),
      enableLiveSession: new FormControl(),
      clientName: new FormControl(),
      clientTimezone: new FormControl(),
      defaultManagerUserRole: new FormControl(),
      defaultInsightUserRole: new FormControl(),
      defaultManagerUserViewIds: new FormControl(),
      defaultInsightUserViewIds: new FormControl(),
      hasBenchMarks: new FormControl(),
      featureWhiteList: new FormControl(),
      tokenExpiryInMinutes: new FormControl(),
      needsSurveyResponseEscalation: new FormControl(),
      smsNumber: new FormControl(),
      unsubscribePrefix: new FormControl(),
      weeklyEmailEnabled: new FormControl(),
      users: new FormControl(),
      views: new FormControl(),
      header_from: new FormControl(),
      smtp_mailfrom: new FormControl(),
      unsubscribeUrl: new FormControl(),
    });
    this.slackBotForm = this.fb.group({
      botUserOAuthToken: new FormControl(),
    });

    this.rolesService.getRoles().subscribe(data => this.roles = data.body);
    this.viewService.getViewsForUser().subscribe(views => this.views = views.body);
    this.tzFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((): void => {
        this.filterTimeZones();
      });

  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterTimeZones(): void {
    // @ts-ignore
    const timeZones = ConfigService.getTimeZones();
    const filterOn = this.tzFilterCtrl.value;
    this.filteredTimeZones =
      _.filter(timeZones, s => s.value.toLowerCase().indexOf(filterOn.toLowerCase()) > -1);
    // console.log('this.filteredTimeZones', this.filteredTimeZones);
  }

  defaultValues() {
    if (!this.settings) {
      return;
    }
    const defaultSurveyEscalation = {
      reportToEmail: [],
      customLookoutKeyWords: [
        'worried',
        'disappointed',
        'delay',
        'delayed',
        'irresponsible',
        'non responsive',
        'unresponsive'],
      longCommentsMinChars: 50,
      schedule: 'hourly',
      hours: 1,
      minScore: 3,
      immediateAlert: {
        minScore: { enabled: true, value: 3 },
        alertLongComments: { enabled: true, value: 3 }
      },
      npsAlert: []
    };
    this.settings.surveyEscalation = _.defaults(this.settings.surveyEscalation, defaultSurveyEscalation);
    this.settings.emailFooterText = this.settings.emailFooterText || this.defaultEmailFooterText;
    this.settings.replyToEmail = this.settings.replyToEmail || 'no-reply@survale.com';
    this.settings.needsSurveyResponseEscalation = this.settings.needsSurveyResponseEscalation || 0;
    const defaultsFalse = [
      'identifyClientsInUnsubscribeLink', 'insightsEnabled', 'isMailBoxMonitored', 'hideEmailTrackingCode',
      'weeklyEmailEnabled', 'compareWidgetsEnabled', 'smsEnabled', 'forcePwdChange', 'enableLiveSession'
    ];
    _.each(defaultsFalse, v => {
      this.settings[v] = this.settings[v] || false;
    });
  }

  /**
   * Set the form value when received from parent
   */
  ngOnChanges(): void {
    this.defaultValues();
    if (this.clientSettingsForm) {
      this.settings = this.settings || {};
      for (const field of 'header_from,smtp_mailfrom,unsubscribeUrl'.split(',')) {
        this.settings[field] = _.get(this.settings, `sesExConfig.${field}`, '');
      }

      _.defaults(this.settings, { excludeUnsubscribeLinkInEmail: false });
      console.log('this.settings', this.settings);
      this.clientSettingsForm.patchValue(this.settings);
    }
    if (this.slackBotForm) {
      this.slackBotForm.patchValue(this.settings.slackBotSettings);
    }
  }

}
