import { Injectable } from '@angular/core';
import { ConfigService, PermissionTypes } from '@services/config.service';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildMenuNavigationItem {
  state: string;
  additionalStates?: string[];
  name: string;
  visible: boolean;
  type?: string;
  permission?: PermissionTypes;
  requiresSurvaleAdmin?: boolean;
  data?: any;
  customCssClass?: string;
  children?: ChildMenuNavigationItem[];
}

export interface MainMenuNavigationItem {
  state: string;
  name: string;
  type: string;
  icon?: string;
  iconClass?: string;
  badge?: BadgeItem[];
  children?: ChildMenuNavigationItem[];
  visible: boolean;
  data?: any;
  permission?: PermissionTypes;
  requiresSurvaleAdmin?: boolean;
  customCssClass?: string;
}

const MENUITEMS: MainMenuNavigationItem[] = [

  {
    state: 'dashboards',
    name: 'Dashboards',
    type: 'sub',
    iconClass: 'nav-icon fa fa-bar-chart',
    children: [],
    visible: false,
    permission: PermissionTypes.Dashboards
  },
  {
    state: 'views',
    name: 'Views',
    type: 'sub',
    iconClass: 'nav-icon fa fa-bar-chart',
    children: [],
    visible: true,
    permission: PermissionTypes.Views
  },
  {
    state: 'employee-campaign-results',
    name: 'Employee Campaigns',
    type: 'sub',
    iconClass: 'nav-icon fa fa-user-o',
    children: [],
    visible: true,
    permission: PermissionTypes.EmployeeCampaignResults
  },
  {
    state: 'insights',
    name: 'Insights',
    type: 'sub',
    iconClass: 'nav-icon fa fa-share',
    children: [],
    visible: false
  },
  {
    state: 'saved-user-filters',
    name: 'Saved Filters',
    type: 'sub',
    iconClass: 'nav-icon fa fa-filter',
    children: [],
    visible: true
  },
  {
    state: 'toolkit',
    name: 'Toolkit',
    type: 'sub',
    iconClass: 'nav-icon fa fa-briefcase',
    children: [
      {state: 'surveys', name: 'Surveys', permission: PermissionTypes.Surveys, visible: true},
      {state: 'campaigns', name: 'Campaigns', permission: PermissionTypes.Campaigns, visible: true},
      {state: 'email-lists', name: 'Lists', permission: PermissionTypes.EmailLists, visible: true},
      {state: 'sites', name: 'Sites', permission: PermissionTypes.Campaigns, visible: true},
      {state: 'views', name: 'Views', permission: PermissionTypes.Views, visible: true},
      {state: 'shared-dashboards', name: 'Shared Dashboards', permission: PermissionTypes.ShareDashboards, visible: true},
      {state: 'shared-insights', name: 'Shared Insights', permission: PermissionTypes.SurveyInsights, visible: true},
      {
        state: 'shared-filtered-dashboards',
        name: 'Shared Filtered Dashboards',
        permission: PermissionTypes.ShareFilteredDashboards,
        visible: true
      },
      {state: 'indexes', name: 'Indexes', permission: PermissionTypes.Indexes, visible: true},
      {state: 'employee-lists', name: 'Employee Lists', permission: PermissionTypes.EmployeeLists, visible: true},
      {
        state: 'employee-campaigns',
        name: 'Employee Campaigns',
        permission: PermissionTypes.EmployeeCampaigns,
        visible: true,
        // requiresSurvaleAdmin: true
      },
      // TODO {state: 'api-integrator', name: 'API Integrator', permission: PermissionTypes.ManageAPIIntegration, visible: true},
      {state: 'url-shortener', name: 'URL Shortener', permission: PermissionTypes.URLShortener, visible: true},
      // TODO: CHANGE PermissionTypes.SurvaleStart
      {state: 'survale-start', name: 'Survale Start', permission: PermissionTypes.SurvaleStart, visible: true},
      
    ],
    visible: true
  },
  
  {
    state: 'reports',
    name: 'Reports',
    type: 'sub',
    iconClass: 'nav-icon fa fa-flag',
    children: [
      {state: 'surveys', name: 'Surveys', visible: true},
      {state: 'campaigns', name: 'Campaigns', visible: true, permission: PermissionTypes.CampaignResults},
      {state: 'employee-campaigns', name: 'Employee Campaigns', visible: true, permission: PermissionTypes.EmployeeCampaignResults},
    ],
    visible: true,
    permission: PermissionTypes.ViewReports
  },
  {
    state: 'admin',
    name: 'Admin',
    type: 'sub',
    iconClass: 'nav-icon fa fa-user-with-tie',
    children: [
      {state: 'client', name: 'Client', permission: PermissionTypes.Clients, requiresSurvaleAdmin: true, visible: true},
      {state: 'users', name: 'Users', permission: PermissionTypes.Users, visible: true},
      {state: 'roles', name: 'Roles', permission: PermissionTypes.ManageRoles, visible: true},
      {state: 'api-call-logs', name: 'Api Call Logs', permission: PermissionTypes.ApiCallLogs, visible: true},
      {state: 'promo-codes', name: 'Promo Codes', permission: PermissionTypes.PromoCodes, visible: true},
      {state: 'gdpr', name: 'GDPR', permission: PermissionTypes.GDPR, visible: true},
      {state: 'gdpr-log', name: 'GDPR Log', permission: PermissionTypes.GDPR, visible: true},
      {
        state: 'smart-recruiters-auth',
        name: 'oAuth 2.0',
        permission: PermissionTypes.ManageOAuth,
        visible: true
      },
      {state: 'sftp-access', name: 'Sftp Access', permission: PermissionTypes.SftpAccess, visible: true},
      // does not work for ICIMS
      // {
      //   state: 'icims-auth',
      //   name: 'ICIMS Auth',
      //   permission: PermissionTypes.ManageOAuth,
      //   visible: true
      // },
    ],
    visible: false,
  },
  {
    state: ConfigService.ConfigServiceData.survaleReleaseNotesLink,
    name: 'Survale Community',
    type: 'extLink',
    permission: PermissionTypes.ReleaseNotes,
    visible: false,
  },
  {
    state: 'be-settings',
    name: 'Settings',
    type: 'link',
    visible: false,
  },
];

@Injectable()
export class MenuItems {

  constructor() {
    const candeFakeMenuItem = {
      state: 'cande-fake-menu-1',
      name: 'Survale Enterprise',
      type: 'sub',
      iconClass: '',
      children: [],
      visible: true,
      permission: PermissionTypes.Views
    };

    candeFakeMenuItem.children.push({
      state: ConfigService.ConfigServiceData.survaleLiteVsEnterprise,
      name: 'Lite Vs Enterprise',
      permission: PermissionTypes.Views,
      visible: true,
      type: 'extLink'
    });

    for (const v of ConfigService.ConfigServiceData.candeVideos) {
      candeFakeMenuItem.children.push(
        {
          state: v.id, name: v.menuTitle, permission: PermissionTypes.Views,
          visible: true, type: 'videos'
        }
      );
    }

    const candeFakeDashboards = {
      state: 'cande-fake-menu-2',
      name: 'Enterprise Dashboards',
      type: 'sub',
      iconClass: '',
      children: [],
      visible: true,
      permission: PermissionTypes.Views
    };

    for (const v of ConfigService.ConfigServiceData.candeDashboards) {
      candeFakeDashboards.children.push(
        {
          state: v.id, name: v.menuTitle, permission: PermissionTypes.Views,
          visible: true, type: 'images'
        }
      );
    }

    MENUITEMS.push(candeFakeMenuItem);
    MENUITEMS.push(candeFakeDashboards);

  }

  getAll(): MainMenuNavigationItem[] {
    // console.log('getAll called')
    return MENUITEMS;
  }

  // add(menu: MainMenuNavigationItem) {
  //   MENUITEMS.push(menu);
  // }
}
