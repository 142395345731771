import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class OAuthService extends BaseHttpService {
  getSmartRecruitersRedirectURI() {
    return this.get('ats-providers-auth/sr-redirect-uri');
  }

  validateICIMSRedirectURI() {
    return this.get('ats-providers-auth/icims-redirect-callback');
  }

  checkSmartRecruitersAuth() {
    return this.get('ats-providers-auth/sr-confirm-auth');
  }

  checkICIMSAuth() {
    return this.get('ats-providers-auth/icims-confirm-auth');
  }

  getClientHash() {
    return this.get('ats-providers-auth/get-client-hash' );
  }

  sendTestSlackMessage(email: string) {
    return this.post('ats-providers-auth/send-test-slack-message', {email});
  }

}
