import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class CampaignsService extends BaseHttpService {

  private resource = 'campaigns';

  /**
   * Life cycle method
   * @param http HttpClient
   * @param configService ConfigService
   * @param authenticationService Authentication Service
   */
  constructor(
    public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  getCampaigns(limit = 10, offset = 0, contains = '', tags = '') {

    // console.log('limit and offset ', limit, offset);
    const params: any = {
      limit,
      offset,
      contains,
      loadCounts: true,
      tags
    };

    return this.get('campaign-list', '', params);
  }

  getAllCampaigns() {
    return this.get('all-campaigns', '');
  }

  setCampaignToNotStarted(id) {
    return this.post('campaign/set-campaign-to-not-started', {id});
  }

  pushCampaignToProduction(id) {
    return this.post(`campaign/push-production/${id}`, {id});
  }

  /**
   * POST request for getting matching records for campaign
   * @param campaign
   * @param nextToken
   */
  getMatchingRecords(campaign: any, nextToken = '') {
    // console.log('campaign', campaign);
    if (campaign.employeeCampaignType) {
      return this.post('employee-campaign/matching-records', campaign);
    } else {
      console.log(campaign.tempNextToken);
      return this.post('campaign/matching-records', campaign,
        {nextToken: encodeURIComponent(nextToken), limit: 100});
    }
  }

  /**
   * POST Request for adding campagin
   * @param campaign
   */
  addCampaign(campaign: any) {
    return this.post('campaign', campaign);
  }

  /**
   * Delete selected campaign with given id
   * @param id
   */
  deleteCampaign(id: string) {
    return this.delete('campaign', id);
  }

  /**
   * Send test email
   * @param payload
   */
  sendTestEmail(payload: any) {
    const resource = payload.isEmployeeCampaign ? 'employee-campaign/email-test/0' : 'campaign/email-test/0';
    return this.post(resource, payload);
  }

  /**
   * Send Test SMS
   * @param payload
   */
  sendTestSMS(payload): any {
    const resource = payload.isEmployeeCampaign ? 'employee-campaign/sms-test/0' : 'campaign/sms-test/0';
    return this.post(resource, payload);
  }

  sendTestSlack(payload): any {
    const resource = payload.isEmployeeCampaign ? 'employee-campaign/slack-test/0' : 'campaign/slack-test/0';
    return this.post(resource, payload);
  }
  /**
   * Return logs
   * @param id campaign id
   * @param isEmployeeCampaign
   * @param nextToken
   */
  getCampaignLogsByEmail(email: string, isEmployeeCampaign: boolean, nextToken: string): any {
    const resource = isEmployeeCampaign ? 'employee-campaign/logs-by-email' : 'campaign/logs-by-email';
    return this.get(resource, email, {limit: 50, nextToken});
  }

  /**
   * Return logs
   * @param id campaign id
   * @param isEmployeeCampaign
   * @param nextToken
   */
  getCampaignLogs(id: string, isEmployeeCampaign: boolean, nextToken: string): any {
    const resource = isEmployeeCampaign ? 'employee-campaign/logs' : 'campaign/logs';
    return this.get(resource, id, {limit: 50, nextToken});
  }

  /**
   * Return logs
   * @param id campaign id
   * @param isEmployeeCampaign
   * @param nextToken
   */
  getCampaignEmailLogs(emailListItemId: string, isEmployeeCampaign: boolean, nextToken: string): any {
    const resource = isEmployeeCampaign ? 'employee-campaign/logs-by-email' : 'campaign/logs-by-email';
    return this.get(resource, emailListItemId, {limit: 50, nextToken});
  }
  getCampaignListNamesCache(): any {
    return this.get( 'campaign-list-ids-names', undefined, {});
  }
  /**
   * Return logs
   * @param id campaign id
   * @param isEmployeeCampaign
   */
  getScheduledCampaigns(id: string, isEmployeeCampaign: boolean, nextToken: string): any {
    const resource = isEmployeeCampaign ? 'employee-campaign/scheduled' : 'campaign/scheduled';
    if (nextToken.length > 0) {
      return this.get(resource, id, {nextToken: encodeURIComponent(nextToken)});
    } else {
      return this.get(resource, id, {});
    }
  }

  deleteScheduled(id: string): any {
    const resource = 'campaign/delete-scheduled';
    return this.delete(resource, id);
  }

  /**
   * Return campaign object by campaign id
   * @param id id of campaign
   */
  getCampaignById(id: string): any {
    return this.get('campaign', id);
  }

  /**
   * Update campaign
   * @param payload Campaign object
   * @param id campaign id
   */
  updateCampaignById(payload, id): any {
    return this.put('campaign', payload, id);
  }
}
